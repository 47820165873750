<script>
import { queryTablesV2 } from '@/services/api/v2/tables.v2.api';
import {
  createRecordV2,
  deleteRecordV2,
  downloadDocumentV2,
  updateRecordV2,
} from '@/services/api/v2/records.v2.api';
import { mapGetters } from 'vuex';
import { getColumnsV2 } from '@/services/api/v2/columns.v2.api';
import AntInput from '@/components/AntInput.vue';
import { connectionTypes } from '@/modules/ReusePotentialIndex/queries';
import { lifespanOptions } from '@/modules/ReusePotentialIndex/lifespan';

export default {
  name: 'ReusePotentialIndexCalculation',
  components: { AntInput },
  data: () => {
    return {
      newComponentMenu: false,
      newComponentName: '',
      components: [],
      componentsColumns: [],
      componentsTableId: '',
      componentsLoading: false,
      componentsListIndex: null,
      focusedComponent: null,
      componentConstructionDeviationDateMenu: false,

      connections: [],
      connectionsTableId: null,
      connectionsLoading: false,
      newConnectionMenu: false,
      newConnectionComponent: null,
      newConnectionType: null,

      focusedCrossSection: null,

      editMode: false,
    };
  },
  computed: {
    ...mapGetters(['project']),
    editable() {
      if (this.focusedComponent.status === 'created') {
        return true;
      }
      return this.focusedComponent.status === 'completed' && this.editMode;
    },
    componentLifespan() {
      return this.lifespanOptions().find(
        (x) => x.id === this.focusedComponent.technical_lifespan
      )?.label;
    },
  },
  watch: {
    focusedComponent(newValue, oldValue) {
      if (newValue) {
        if (newValue.id !== this.$route.params?.component) {
          this.$router.push({ params: { component: newValue.id } });
        }
        this.fetchComponentConnections();
        this.componentsListIndex = this.components.findIndex(
          (c) => c.id === this.$route.params.component
        );
      }
    },
  },
  async mounted() {
    await this.fetchComponents();
    if (this.$route.params.component) {
      this.focusedComponent = this.components.find(
        (c) => c.id === this.$route.params.component
      );
      this.componentsListIndex = this.components.findIndex(
        (c) => c.id === this.$route.params.component
      );
    }
  },
  methods: {
    lifespanOptions() {
      return lifespanOptions;
    },
    connectionTypes() {
      return connectionTypes;
    },
    async fetchComponents() {
      this.componentsLoading = true;
      try {
        let { components, crossSections } = await queryTablesV2({
          tables: [
            {
              name: 'CFFA_RPI_CROSS_SECTIONS',
              project: this.project.id,
              as: 'crossSections',
              sortBy: 'title',
              records: [this.$route.params.crossSection],
              columns: [
                {
                  name: 'variant',
                  conditions: [
                    {
                      operator: '=',
                      value: this.$route.params.variant,
                    },
                  ],
                },
                {
                  name: 'title',
                },
                {
                  name: 'image',
                },
                {
                  name: 'coverage_ratio',
                },
                {
                  name: 'score',
                },
                {
                  name: 'score_revision_time',
                },
              ],
            },
            {
              name: 'CFFA_RPI_COMPONENTS',
              project: this.project.id,
              as: 'components',
              sortBy: 'nr',
              columns: [
                {
                  name: 'cross_section',
                  conditions: [
                    {
                      operator: '=',
                      value: this.$route.params.crossSection,
                    },
                  ],
                },
                {
                  name: 'nr',
                },
                {
                  name: 'name',
                },
                {
                  name: 'description',
                },
                {
                  name: 'status',
                },
                {
                  name: 'product_lvl',
                },
                {
                  name: 'component_function',
                },
                {
                  name: 'component_additional_function',
                },
                {
                  name: 'building_function',
                },
                {
                  name: 'building_additional_function',
                },
                {
                  name: 'generic_component',
                },
                {
                  name: 'construction_date_deviation',
                },
                {
                  name: 'technical_lifespan',
                },
              ],
            },
          ],
        });
        this.components = components.records;
        this.components.forEach((c) => {
          c.progress = this.getComponentProgress(c);
        });
        this.componentsTableId = components.id;
        this.componentsColumns = await getColumnsV2(components.id);

        this.focusedCrossSection = crossSections.records[0] ?? null;
        if (this.focusedCrossSection.image) {
          this.focusedCrossSection.image = await downloadDocumentV2(
            crossSections.id,
            this.focusedCrossSection.id,
            this.focusedCrossSection.image?.id ?? this.focusedCrossSection.image
          );
        }
      } catch (e) {}
      this.componentsLoading = false;
    },
    async saveComponent() {
      this.componentsLoading = true;
      try {
        let component = await createRecordV2(this.componentsTableId, {
          cross_section: this.$route.params.crossSection,
          name: this.newComponentName,
          nr:
            this.components.length > 0
              ? [...this.components].sort((a, b) => b.nr - a.nr)[0].nr + 1 ?? 1
              : 0,
          status: 'created',
        });
        this.components.push(component);
        this.focusedComponent = component;
        this.componentsListIndex = this.components.findIndex(
          (c) => c.id === this.focusedComponent.id
        );
        this.newComponentMenu = false;
        this.newComponentName = '';
      } catch (e) {}
      this.componentsLoading = false;
    },
    completeComponent() {
      this.focusedComponent.status = 'completed';
      this.updateComponent();
    },
    async updateComponent() {
      this.componentsLoading = true;
      try {
        let component = await updateRecordV2(
          this.componentsTableId,
          this.focusedComponent.id,
          this.focusedComponent
        );
        component.progress = this.getComponentProgress(component);
        Object.assign(
          this.components.find((c) => c.id === this.focusedComponent.id),
          component
        );
        this.focusedComponent = { ...component };
      } catch (e) {}
      this.componentsLoading = false;
    },
    async fetchComponentConnections() {
      this.connectionsLoading = true;
      try {
        let { connections } = await queryTablesV2({
          tables: [
            {
              name: 'CFFA_RPI_COMPONENTS_CONNECTIONS',
              project: this.project.id,
              as: 'connections',
              columns: [
                {
                  name: 'to_component',
                  conditions: [
                    {
                      operator: '=',
                      value: this.$route.params.component,
                    },
                  ],
                },
                {
                  name: 'from_component',
                  conditions: this.components.map((c) => {
                    return {
                      operator: '=',
                      value: c.id,
                    };
                  }),
                },
                {
                  name: 'type',
                },
              ],
            },
          ],
        });
        this.connectionsTableId = connections.id;

        this.connections = connections.records;
        this.connections.forEach((c) => {
          c.from_component = this.components.find(
            (x) => x.id === c.from_component
          );
          c.from_component.progress = this.getComponentProgress(
            c.from_component
          );
          c.type = connectionTypes.find((t) => t.type === c.type);
        });
      } catch (e) {}
      this.connectionsLoading = false;
    },
    getComponentProgress(component) {
      const propertiesToCheck = [
        'product_lvl',
        'component_function',
        'component_additional_function',
        'building_function',
        'building_additional_function',
        'generic_component',
        'technical_lifespan',
      ];
      let filledProps = 0;

      for (let i = 0; i < propertiesToCheck.length; i++) {
        let key = propertiesToCheck[i];
        if (component[key] !== null) {
          filledProps++;
        }
      }
      // calculating percentage
      return (filledProps / propertiesToCheck.length) * 100;
    },
    async saveConnection() {
      this.connectionsLoading = true;
      try {
        let connection = await createRecordV2(this.connectionsTableId, {
          to_component: this.focusedComponent.id,
          from_component: this.newConnectionComponent.id,
          type: this.newConnectionType.type,
        });
        connection.from_component = this.components.find(
          (x) => x.id === connection.from_component
        );
        connection.from_component.progress = this.getComponentProgress(
          connection.from_component
        );

        connection.type = connectionTypes.find(
          (t) => t.type === connection.type
        );
        this.connections.push(connection);
        this.newConnectionMenu = false;
        this.newConnectionType = '';
        this.newConnectionComponent = '';
      } catch (e) {}
      this.connectionsLoading = false;
    },
    async deleteConnection(connectionId) {
      this.connectionsLoading = true;
      try {
        await deleteRecordV2(this.connectionsTableId, connectionId);
        this.connections = this.connections.filter(
          (c) => c.id !== connectionId
        );
      } catch (e) {}
      this.connectionsLoading = false;
    },

    async deleteComponent() {
      this.componentsLoading = true;
      try {
        let { connections } = await queryTablesV2({
          tables: [
            {
              name: 'CFFA_RPI_COMPONENTS_CONNECTIONS',
              project: this.project.id,
              as: 'connections',
              columns: [
                {
                  name: 'to_component',
                },
                {
                  name: 'from_component',
                  conditions: [
                    {
                      operator: '=',
                      value: this.$route.params.component,
                    },
                  ],
                },
                {
                  name: 'type',
                },
              ],
            },
          ],
        });

        await deleteRecordV2(
          this.connectionsTableId,
          ...new Set([
            ...this.connections.map((c) => c.id),
            ...connections.records.map((c) => c.id),
          ])
        );
        await deleteRecordV2(this.componentsTableId, this.focusedComponent.id);

        this.components = this.components.filter(
          (c) => c.id !== this.focusedComponent.id
        );
        this.focusedComponent = null;
        this.componentsListIndex = null;
      } catch (e) {
        console.log(e);
      }
      this.componentsLoading = false;
    },
  },
};
</script>

<template>
  <div class="px-5">
    <div class="background-white d-flex" style="width: 250px">
      <v-list subheader class="d-flex flex-column full-width" two-line>
        <div class="subheader">
          1. Onderdelen
          <v-spacer />
          <v-menu
            v-model="newComponentMenu"
            right
            :close-on-content-click="false"
          >
            <template #activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>

            <div class="background-white pa-1">
              <v-text-field
                v-model="newComponentName"
                placeholder="Naam"
                filled
                hide-details
                autofocus
                single-line
                class="mb-2"
                @keydown.enter="saveComponent"
              />
            </div>
          </v-menu>
        </div>
        <v-progress-linear
          v-show="componentsLoading"
          indeterminate
          height="5"
          color="primary"
        />
        <v-list-item-group
          v-model="componentsListIndex"
          color="primary"
          class="flex-grow-1 overflow-y-auto px-2"
        >
          <v-list-item
            v-for="component in components"
            :key="component.id"
            class="mb-2"
            :style="{
              'border-left':
                component.progress > 0 && component.status !== 'completed'
                  ? 'solid 5px orange'
                  : component.status === 'created'
                    ? 'solid 5px red'
                    : 'solid 5px green',
            }"
            @click="focusedComponent = { ...component }"
          >
            <span class="fs-16 mr-2">{{ component.nr }}.</span>
            <v-list-item-content>
              <v-list-item-title>{{ component.name }}</v-list-item-title>
              <v-list-item-subtitle>{{
                component.description
              }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action
              v-if="component.progress > 0 && component.status !== 'completed'"
            >
              <v-progress-circular
                color="primary"
                :value="component.progress"
              />
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </div>
    <div class="d-flex flex-column pl-5 flex-grow-1">
      <div
        class="background-white full-width d-flex mb-5"
        style="min-height: 300px"
      >
        <div class="d-flex flex-column flex-grow-1 pa-5">
          <div class="subheader">{{ focusedCrossSection?.title }}</div>
        </div>

        <v-dialog width="90vw" style="height: 90vh">
          <template #activator="{ on, attrs }">
            <v-img
              max-width="500"
              max-height="300"
              contain
              class="my-5 mr-5 radius-8 c-pointer"
              :style="{
                background: focusedCrossSection?.image ? '' : 'lightgrey',
              }"
              style="position: relative"
              :src="`data:${focusedCrossSection?.image?.mimetype};base64, ${focusedCrossSection?.image?.file}`"
              v-bind="attrs"
              v-on="on"
            />
          </template>

          <v-img
            class="radius-8"
            contain
            :src="`data:${focusedCrossSection?.image?.mimetype};base64, ${focusedCrossSection?.image?.file}`"
          />
        </v-dialog>
      </div>
      <div
        v-if="focusedComponent"
        class="d-flex flex-grow-1 background-white d-flex pa-5"
        style="height: 0"
      >
        <div style="max-width: 70%" class="overflow-y-auto">
          <v-list subheader>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="subheader-small"
                  >1. Benaming
                  <v-spacer />

                  <v-btn
                    v-if="focusedComponent.status !== 'completed'"
                    color="primary"
                    :disabled="focusedComponent.progress !== 100"
                    small
                    @click="completeComponent"
                    ><v-progress-circular
                      v-if="focusedComponent.progress !== 100"
                      v-model="focusedComponent.progress"
                      color="primary"
                      size="20"
                      class="mr-2"
                    />
                    Complete</v-btn
                  >
                  <v-btn
                    v-if="focusedComponent.status === 'completed'"
                    icon
                    :color="editMode ? 'primary' : ''"
                    @click="editMode = !editMode"
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-menu right :close-on-content-click="false">
                    <template #activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>

                    <div class="background-white pa-1">
                      <v-list-item style="max-width: 400px">
                        <v-list-item-content>
                          <v-list-item-title
                            >Weet je zeker dat je dit onderdeel wilt
                            verwijderen?</v-list-item-title
                          >
                          <v-list-item-subtitle
                            >Alle verbindingen zullen verloren
                            gaan</v-list-item-subtitle
                          >
                          <v-btn
                            elevation="0"
                            color="primary"
                            small
                            @click="deleteComponent"
                            >verwijder</v-btn
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </div>
                  </v-menu>
                </v-list-item-title>

                <v-list-item-subtitle
                  >Geef extra informatie aan over het
                  onderdeel</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <div class="px-5 d-flex mb-5">
              <ant-input label="Naam" top-margin="mt-0" class="mr-5">
                <template #input-field>
                  <v-text-field
                    v-model="focusedComponent.name"
                    hide-details
                    single-line
                    filled
                    :readonly="!editable"
                    dense
                    @change="updateComponent"
                  />
                </template>
              </ant-input>
              <ant-input label="Beschrijving" top-margin="mt-0" is-optional>
                <template #input-field>
                  <v-text-field
                    v-model="focusedComponent.description"
                    hide-details
                    single-line
                    filled
                    dense
                    :readonly="!editable"
                    @change="updateComponent"
                  />
                </template>
              </ant-input>
            </div>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="subheader-small"
                  >2. Productniveau
                  <v-menu open-on-hover offset-x>
                    <template #activator="{ on, attrs }">
                      <v-btn v-bind="attrs" icon v-on="on">
                        <v-icon>mdi-information-box-outline</v-icon>
                      </v-btn>
                    </template>

                    <v-card max-width="600">
                      <v-card-text>
                        <p>
                          Deze indicator analyseert het assemblageproces op de
                          bouwplaats. Het is belangrijk om onderscheid te maken
                          tussen materiaalverwerking direct op de bouwplaats,
                          assemblage van individuele elementen op de bouwplaats
                          en assemblage van prefab componenten/systemen op de
                          bouwplaats.
                          <br />
                          Minder werk op de bouwplaats tijdens montage/demontage
                          resulteert in minder beschadiging van bouwonderdelen
                          tijden het montage-/demontageproces en een sneller
                          montage-/demontageproces.
                          <br /><br />Overzicht van de verschillende product
                          niveaus (zie illustratie hieronder)
                        </p>
                        <v-img
                          src="./assets/assembly_lvl.png"
                          contain
                          max-width="500"
                          max-height="300"
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-list-item-title>
                <v-list-item-subtitle
                  >Geef aan of het onderdeel los materiaal, een element uit één
                  stuk of deel van een samengestelde systeem/component
                  is.</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <div class="px-5 d-flex mb-5">
              <ant-input label="Productniveau" top-margin="mt-0">
                <template #input-field>
                  <v-select
                    v-model="focusedComponent.product_lvl"
                    :items="
                      componentsColumns.find((c) => c.name === 'product_lvl')
                        .options_value
                    "
                    hide-details
                    single-line
                    :readonly="!editable"
                    filled
                    dense
                    @change="updateComponent"
                  />
                </template>
              </ant-input>
            </div>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="subheader-small"
                  >3. Onderdeel functie
                  <v-menu open-on-hover offset-x>
                    <template #activator="{ on, attrs }">
                      <v-btn v-bind="attrs" icon v-on="on">
                        <v-icon>mdi-information-box-outline</v-icon>
                      </v-btn>
                    </template>

                    <v-card max-width="600">
                      <v-card-text>
                        <p>
                          Hoe minder relaties een onderdeel heeft, des te
                          makkelijker het is om het onderdeel te recupereren.
                          Een onderdeel met een basis/dragende of intermediaire
                          functie zorgt er voor dat onderdelen onderling niet
                          direct verbonden zijn maar via een intermediair
                          tussenstuk, waardoor ze sneller gedemonteerd kunnen
                          worden zonder elkaar te raken.
                        </p>
                        <v-img
                          src="./assets/component_function.png"
                          contain
                          max-width="500"
                          max-height="300"
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-list-item-title>
                <v-list-item-subtitle style="white-space: normal"
                  >Vul in of het onderdeel al dan niet een base/dragende of
                  intermediaire functie heeft binnen een bouwdeel. En verifieer
                  of er andere functies zijn anders dan afwerking (installaties,
                  niet dragende scheiding).</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <div class="px-5 d-flex mb-5">
              <ant-input
                label="Onderdeel functie"
                top-margin="mt-0"
                class="mr-5"
              >
                <template #input-field>
                  <v-select
                    v-model="focusedComponent.component_function"
                    :items="
                      componentsColumns.find(
                        (c) => c.name === 'component_function'
                      ).options_value
                    "
                    hide-details
                    single-line
                    filled
                    dense
                    :readonly="!editable"
                    @change="updateComponent"
                  />
                </template>
              </ant-input>
              <ant-input
                label="Bijkomende onderdeel functie(s) anders dan afwerking"
                top-margin="mt-0"
              >
                <template #input-field>
                  <v-select
                    v-model="focusedComponent.component_additional_function"
                    :items="
                      componentsColumns.find(
                        (c) => c.name === 'component_additional_function'
                      ).options_value
                    "
                    hide-details
                    single-line
                    filled
                    :readonly="!editable"
                    dense
                    @change="updateComponent"
                  />
                </template>
              </ant-input>
            </div>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="subheader-small"
                  >4. Gebouw functie
                  <v-menu open-on-hover top>
                    <template #activator="{ on, attrs }">
                      <v-btn v-bind="attrs" icon v-on="on">
                        <v-icon>mdi-information-box-outline</v-icon>
                      </v-btn>
                    </template>

                    <v-card max-width="600">
                      <v-card-text>
                        <p>
                          Gebouwfunctie is niet verplicht voor deze
                          berekening.<br />
                          Gebouwfuncties zijn:<br />
                          • dragende/load bearing functie (zoals fundering,
                          constructieve wanden, kolommen, balken, vloeren),<br />
                          • omhullende/enclosing functie (zoals gevels en
                          daken), scheidende/partitioning functies (zoals
                          binnenwanden),<br />
                          • afwerkings-/finishing functie (zoals verlaagde
                          plafond, vloerafwerking),<br />
                          • installatie/servicingfunctie (zoals elektra, riool,
                          klimaatinstallaties)<br />
                          <br />
                          Zorg er voor dat onderdelen monofunctioneel zijn in
                          plaats van dat ze meerdere functies vervullen.
                        </p>
                        <v-img
                          src="./assets/building_function.png"
                          contain
                          max-width="500"
                          max-height="300"
                        />
                      </v-card-text>
                    </v-card>
                  </v-menu>
                </v-list-item-title>
                <v-list-item-subtitle style="white-space: normal"
                  >Vul in de gebouwfunctie van het onderdeel. Voeg toe of het
                  meerdere functies vervult, en in geval van component/system of
                  het ook direct voor andere functies gebruikt kan worden
                  (generiek component/system).</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <div class="px-5 d-flex mb-5">
              <ant-input
                label="Gebouwfunctie"
                top-margin="mt-0"
                class="mr-5"
                is-optional
              >
                <template #input-field>
                  <v-text-field
                    v-model="focusedComponent.building_function"
                    hide-details
                    single-line
                    filled
                    dense
                    :readonly="!editable"
                    @change="updateComponent"
                  />
                </template>
              </ant-input>
              <ant-input
                label="Bijkomende gebouwfuncties"
                top-margin="mt-0"
                class="mr-5"
              >
                <template #input-field>
                  <v-select
                    v-model="focusedComponent.building_additional_function"
                    :items="
                      componentsColumns.find(
                        (c) => c.name === 'building_additional_function'
                      ).options_value
                    "
                    hide-details
                    :readonly="!editable"
                    single-line
                    filled
                    dense
                    @change="updateComponent"
                  />
                </template>
              </ant-input>
              <ant-input label="Generiek component/systeem" top-margin="mt-0">
                <template #input-field>
                  <v-select
                    v-model="focusedComponent.generic_component"
                    :items="
                      componentsColumns.find(
                        (c) => c.name === 'generic_component'
                      ).options_value
                    "
                    hide-details
                    single-line
                    filled
                    :readonly="!editable"
                    dense
                    @change="updateComponent"
                  />
                </template>
              </ant-input>
            </div>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="subheader-small"
                  >5. Levensduur</v-list-item-title
                >
                <v-list-item-subtitle style="white-space: normal"
                  >Vul de technische levensduur van alle onderdelen in (zie
                  werkblad 'appendix lifecycle'), en de bouwdatum indien deze
                  afwijkt van de algemene informatie.</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
            <div class="px-5 d-flex mb-5">
              <ant-input
                label="Afwijking van bouwdatum"
                top-margin="mt-0"
                class="mr-5"
                is-optional
              >
                <template #input-field>
                  <v-menu
                    v-model="componentConstructionDeviationDateMenu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template #activator="{ on, attrs }">
                      <v-text-field
                        v-model="focusedComponent.construction_date_deviation"
                        prepend-icon="mdi-calendar"
                        readonly
                        single-line
                        filled
                        dense
                        v-bind="attrs"
                        clearable
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="focusedComponent.construction_date_deviation"
                      @input="componentConstructionDeviationDateMenu = false"
                      @change="updateComponent"
                    ></v-date-picker>
                  </v-menu>
                </template>
              </ant-input>

              <ant-input
                label="Technische levensduur (jaren)"
                top-margin="mt-0"
              >
                <template #input-field>
                  <v-select
                    v-model="focusedComponent.technical_lifespan"
                    hide-details
                    single-line
                    filled
                    dense
                    :items="lifespanOptions()"
                    item-value="id"
                    item-text="label"
                    :readonly="!editable"
                    type="number"
                    :return-object="false"
                    @change="updateComponent"
                  >
                    <template #item="{ item }">
                      {{ item.label }} ({{ item.years }})
                    </template>
                  </v-select>
                </template>
              </ant-input>
            </div>
          </v-list>
        </div>
        <div class="flex-grow-1 overflow-y-auto">
          <v-list two-line subheader class="px-2">
            <div class="subheader-small">
              6. Type verbindingen
              <v-menu open-on-hover top>
                <template #activator="{ on, attrs }">
                  <v-btn v-bind="attrs" icon v-on="on">
                    <v-icon>mdi-information-box-outline</v-icon>
                  </v-btn>
                </template>

                <v-card max-width="600">
                  <v-card-text>
                    <p>
                      Selecteer de juiste type verbindingen uit drop-down menu.
                    </p>
                    <v-img
                      src="./assets/connections.jpg"
                      contain
                      max-width="500"
                      max-height="300"
                    />
                  </v-card-text>
                </v-card>
              </v-menu>
              <v-spacer />
              <v-menu
                v-model="newConnectionMenu"
                right
                :close-on-content-click="false"
              >
                <template #activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" :disabled="!editable" v-on="on">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-text>
                    <ant-input label="Onderdeel" top-margin="mt-2">
                      <template #input-field>
                        <v-combobox
                          v-model="newConnectionComponent"
                          :items="components"
                          item-value="id"
                          item-text="name"
                          placeholder="onderdeel"
                          filled
                          autofocus
                          hide-details
                          single-line
                        >
                          <template #item="{ item }">
                            <span class="font-weight-bold mr-2">{{
                              item.nr
                            }}</span>
                            {{ item.name }}
                          </template>
                        </v-combobox>
                      </template>
                    </ant-input>
                    <ant-input label="Type" top-margin="mt-0">
                      <template #input-field>
                        <v-combobox
                          v-model="newConnectionType"
                          :items="connectionTypes()"
                          item-text="type"
                          item-value="type"
                          placeholder="type"
                          filled
                          hide-details
                          single-line
                          class="mb-2"
                        >
                          <template #item="{ item }">
                            <span class="font-weight-bold mr-2">{{
                              item.type
                            }}</span>
                            {{ item.label }}
                          </template>
                        </v-combobox>
                      </template>
                    </ant-input>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn small color="primary" @click="saveConnection"
                      >Save</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-menu>
            </div>
            <v-progress-linear
              v-show="connectionsLoading"
              color="primary"
              height="5"
              indeterminate
            />
            <v-list-item-group
              color="primary"
              class="flex-grow-1 overflow-y-auto px-2"
            >
              <v-list-item
                v-for="connection in connections"
                :key="connection.id"
                class="mb-2"
                :style="{
                  'border-left':
                    connection.from_component.progress > 0 &&
                    connection.from_component.progress < 100
                      ? 'solid 5px orange'
                      : connection.from_component.status === 'created'
                        ? 'solid 5px red'
                        : 'solid 5px green',
                }"
                @click="focusedComponent = { ...connection.from_component }"
              >
                <span class="fs-16 mr-2"
                  >{{ connection.from_component.nr }}.</span
                >
                <v-list-item-content>
                  <v-list-item-title>{{
                    connection.from_component.name
                  }}</v-list-item-title>
                  <v-list-item-subtitle>{{
                    connection.type.label
                  }}</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action>
                  <div class="d-flex align-center">
                    <v-tooltip bottom>
                      <template #activator="{ on, attrs }">
                        <v-chip
                          color="primary"
                          class="mr-2"
                          v-bind="attrs"
                          v-on="on"
                          >{{ connection.type.type }}</v-chip
                        >
                      </template>
                      <span>{{ connection.type.label }}</span>
                    </v-tooltip>
                    <v-btn icon @click="deleteConnection(connection.id)"
                      ><v-icon>mdi-delete</v-icon></v-btn
                    >
                  </div>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.subheader {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: var(--v-primary-base);
  font-size: 20px;
}

.subheader-small {
  display: flex;
  align-items: center;
  color: var(--v-primary-base);
}
</style>
